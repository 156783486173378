import React, {
  useState,
  useEffect
} from 'react';
import { observer } from "mobx-react"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Toolbar,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  Grid,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Hidden,
  Slide,
  Collapse,
  useScrollTrigger,
  ThemeProvider,
  useMediaQuery,
  Divider,
  colors,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import LanguageIcon from '@material-ui/icons/Language';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReplayIcon from '@material-ui/icons/Replay';

import { spring, AnimatedSwitch } from 'react-router-transition';

import {
  SiteLoading,
  Login,
  IssueReportPanel,
  UserProfile,
  GlobalLoadingIndicator,
  AboutPanel,
  OrdersPanel,
} from './views';

import HomePage from './pages/HomePage';
import WarrantyPage from './pages/WarrantyPage';
import DealersPage from './pages/DealersPage';
import BoatsPage from './pages/BoatsPage';
import UsersPage from './pages/UsersPage';
import OrdersPage from './pages/OrdersPage';
import OrderDraftsPage from './pages/OrderDraftsPage';
import MarketingFilesPage from './pages/FilesPage';
import FBInvoiceToNPCSVPage from './pages/orderTools/FBInvoiceToNPCSVPage';

import {
  useSession,
  SessionContext
} from './data/Session';
import { useStatus } from './data/Status';

import {
  HomePageInfo,
} from './views/panels/pages';

import {
  OrderFormPanel,
  OrderFormPanelV2,
} from './views';

import {
  BaseAPI,
} from './data/BaseAPI';

import {
  Session
} from './types';

import logo from './img/new/seafox-signature-bw.png';
import bgImage from './img/bg.jpg';
import './App.css';

import localization from './utils/localizations';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    display: 'flex',
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '720px',
  },
  statusButtonTop: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: theme.zIndex.drawer + 3,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appTitle: {
    flexGrow: 1,
    color: '#ffffff'
  },
  appSubTitle: {
    flexGrow: 1,
    color: '#ffffff',
    fontWeight: 300,
    fontSize: '1rem'
  },
  mainBody: {
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
  },
  headerContainer: {
    backgroundColor: '#2c558d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10
  },
  siteContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },
  headerLogo: {
    display: 'block',
    marginRight: 10,
  },
  headerTextContainer: {
    marginLeft: 20,
  },
  sidebarPanelContainer: {
    marginBottom: 20,
  },
  footer: {
    marginTop: 20,
    textAlign: 'center'
  },
  pageNotFoundContainer: {
    padding: 10,
  },
  pageNotFoundTitle: {
    marginBottom: 20
  },
  betaLabel: {
    fontSize: '0.75rem'
  },
  alert: {
    marginTop: 20,
    marginBottom: 20,
  },
  topMenuMainContainer: {
    display: 'flex',
  },
  topMenuButtonContainer: {
    paddingTop: 10,
  },
  topMenuButton: {
    color: '#ffffff'
  },
  languageSelectorLoginContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: '#ffffff',
  },
  toolbar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbarTitle: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 300,
  },
  drawerContainer: {
    overflow: 'auto',
    padding: 20,
  },
  mainContentContainer: {
    position: 'relative',
  },
  bgPrimaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
  profileDialogContent: {
    padding: 0,
  },
  appUpdateDialogContent: {
    textAlign: 'center',
  },
  siteNameTitle: {
    color: '#000',
  }
}));

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#142b4d',
    },
  },
});

function bounce(val: any) {
  return spring(val, {
    stiffness: 330,
    damping: 35,
  });
}

function UserStatus(props: {
  session: Session;
  showProfileDialog: () => void;
  onLogout: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  return (
    <div className={props.className}>
      <IconButton
        aria-label="display more actions"
        color="inherit"
        onClick={(event: any) => setMoreMenuAnchorEl(event.currentTarget)}
      >
        <Avatar src={props.session?.user?.avatar} alt={`${props.session?.user?.first_name} ${props.session?.user?.last_name}`} />
      </IconButton>
      <Menu
        id="main-menu-more"
        anchorEl={moreMenuAnchorEl}
        keepMounted
        open={Boolean(moreMenuAnchorEl)}
        onClose={() => setMoreMenuAnchorEl(null)}
        style={{zIndex: 99999}}
      >
        <MenuItem
          onClick={() => {
            props.showProfileDialog();
            setMoreMenuAnchorEl(null);
          }}
        >{props.session?.user?.first_name ? `${props.session?.user?.first_name} ${props.session?.user?.last_name}` : props.session?.user?.email}</MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setMoreMenuAnchorEl(null);
            props.onLogout();
          }}
        >Logout</MenuItem>
      </Menu>
    </div>
  );
}


function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const scrollTrigger = useScrollTrigger();
  // child matches will...
  const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      scale: 100,
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: bounce(0),
      scale: bounce(-100),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: bounce(1),
      scale: bounce(0),
    },
  };

  const classes = useStyles();
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [currentAppVersion, setCurrentAppVersion] = useState('1.11.9');
  const [showAppUpdatedDialog, setShowAppUpdatedDialog] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [viewPortHeight, setViewPortHeight] = useState(400);
  const [session, sessionLoading, loadSession, updateSession] = useSession();
  const [status, statusLoading, loadStatus] = useStatus();
  
  useEffect(() => {
    const intervalHandle = setInterval(async () => {
      if (session.success) {
        await loadSession();
      }
    }, 60000);

    const onResizeHandle = () => {
      setViewPortHeight(window.innerHeight);
    }

    window.addEventListener('resize', onResizeHandle);
    onResizeHandle();

    return () => {
      clearInterval(intervalHandle);
      window.removeEventListener('resize', onResizeHandle);
    }
  }, [])

  useEffect(() => {
    if (session.version) {
      if (currentAppVersion) {
        if (currentAppVersion !== session.version) {
          setShowAppUpdatedDialog(true);
        }
      }
      if (currentAppVersion !== session.version) {
        setCurrentAppVersion(session.version);
      }
    }
  }, [session.version]);

  const onLogout = async () => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({}, 'logout/');
    } catch (error) {
      console.error(error);
    }
    loadSession();
  }

  if (!session.success && (sessionLoading || statusLoading)) {
    return (
      <SiteLoading />
    );
  }

  if (!session.success) {
    return (
      <Login
        onLogin={() => {
          loadSession();
          loadStatus();
        }}
      />
    );
  }

  const profileDialog = (
    <Dialog
      open={showProfileDialog}
      onClose={() => setShowProfileDialog(false)}
      aria-labelledby="profile-dialog-title"
      aria-describedby="profile-dialog-description"
    >
      <DialogContent className={classes.profileDialogContent}>
        <UserProfile />
      </DialogContent>
    </Dialog>
  );

  const appUpdatedDialog = (
    <Dialog
      open={showAppUpdatedDialog}
      onClose={() => setShowAppUpdatedDialog(false)}
      aria-labelledby="app-updated-dialog-title"
      aria-describedby="app-updated-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Update Available</DialogTitle>
      <DialogContent className={classes.appUpdateDialogContent}>
        <Typography variant="body1" style={{marginBottom: 20}}>A new version of Sea Fox Portal is available. Please reload your browser.</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReplayIcon />}
          onClick={() => {
            setShowAppUpdatedDialog(false);
            window.location.reload();
          }}
        >Reload</Button>
      </DialogContent>
    </Dialog>
  );

  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        <SessionContext.Provider value={{session: session}}>
          <div className={classes.root} style={{minHeight: viewPortHeight}}>

            <div className={classes.mainBody}>

              <Grid container style={{position: 'relative'}}>
                <Grid item xs={12} className={classes.mainContentContainer} style={{position: 'relative'}}>
                  <AnimatedSwitch
                    atEnter={bounceTransition.atEnter}
                    atLeave={bounceTransition.atLeave}
                    atActive={bounceTransition.atActive}
                    mapStyles={(styles: any) => {
                      const stylesheet = { position: 'relative', opacity: styles.opacity, transform: 'none', height: 'auto' };
                      if ( (styles.scale !== 0)) {
                        stylesheet.transform = `translateX(${styles.scale}%)`;
                      }
                      if ( (styles.scale < 0)) {
                        stylesheet.position = 'absolute';
                      }
                      return stylesheet;
                    }}
                    className="switch-wrapper"
                  >
                    <Route path={`${appConfig.homepage}warranty/`}>
                      <WarrantyPage />
                    </Route>
                    <Route path={`${appConfig.homepage}dealers/`}>
                      <DealersPage />
                    </Route>
                    <Route path={`${appConfig.homepage}boats/`}>
                      <BoatsPage />
                    </Route>
                    <Route path={`${appConfig.homepage}users/`}>
                      <UsersPage />
                    </Route>
                    <Route path={`${appConfig.homepage}marketing-files/`}>
                      <MarketingFilesPage />
                    </Route>
                    <Route path={`${appConfig.homepage}orders/quotes/`}>
                      <OrderDraftsPage />
                    </Route>
                    <Route path={`${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`}>
                      <FBInvoiceToNPCSVPage />
                    </Route>
                    <Route path={`${appConfig.homepage}orders/`}>
                      <OrdersPage />
                    </Route>
                    <Route path={appConfig.homepage}>
                      <Slide appear={false} direction="down" in={!scrollTrigger}>
                        <AppBar position="fixed" className={classes.appbar}>
                          <Toolbar className={classes.toolbar}>
                            <div className={classes.siteContainer}>
                              <RouterLink to={`${appConfig.homepage}`}><img src={logo} alt="" className={classes.headerLogo} style={{height: 30}} /></RouterLink>
                              <Typography className={classes.siteNameTitle}>Dealer Portal</Typography>
                            </div>
                          </Toolbar>
                        </AppBar>
                      </Slide>
                      <Toolbar className={classes.toolbar} />
                      <HomePage />

                      <Route path={`${appConfig.homepage}order-form/`}>
                        <OrderFormPanelV2 />
                      </Route>
                      <Route path={`${appConfig.homepage}order-form-v1/`}>
                        <OrderFormPanel />
                      </Route>
                      <Route path={`${appConfig.homepage}order-form-v2/`}>
                        <OrderFormPanelV2 />
                      </Route>
                      <Route exact path={`${appConfig.homepage}about/`}>
                        <AboutPanel />
                      </Route>
                    </Route>
        
                    <Route>
                      <Paper className={classes.pageNotFoundContainer}>
                        <Typography variant="h5" className={classes.pageNotFoundTitle}>404 Page Not Found</Typography>
                        <Button variant="contained" color="primary" to={`${appConfig.homepage}`} component={RouterLink}>Return to Home Page</Button>
                      </Paper>
                      <IssueReportPanel />
                    </Route>
                  </AnimatedSwitch>
                  <UserStatus
                    session={session}
                    showProfileDialog={() => {
                      setShowProfileDialog(true);
                    }}
                    onLogout={() => {
                      onLogout();
                    }}
                    className={classes.statusButtonTop}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          
          {profileDialog}
          {appUpdatedDialog}
          
          <GlobalLoadingIndicator />
        </SessionContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default observer(App);
